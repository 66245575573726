require('./bootstrap')

import Vue from 'vue'
import App from './App.vue'
import router from './router'
// window._ = require('lodash');
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import store from "./store/store"
import "./axios/axios";
import Toasted from 'vue-toasted';
import VueExcelXlsx from "vue-excel-xlsx";
import LazyImage from '@/components/LazyImage'
import lazysizes from "./plugins/lazysizes.js";
import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
// import ElementUI's styles
import 'element-ui/lib/theme-chalk/index.css';
// import this package's styles
import 'element-tiptap/lib/index.css';
import AOS from 'aos'
import 'aos/dist/aos.css'

// use ElementUI's plugin
Vue.use(ElementUI);
// use this package's plugin
Vue.use(ElementTiptapPlugin, {
  /* plugin options */
});



import scriptData from './addScript.js'
 window.quizell=scriptData;
// Vue.use
Vue.use(BootstrapVue)

Vue.use(IconsPlugin)
Vue.use(Toasted )
Vue.use(lazysizes)
Vue.component("LazyImage", LazyImage)
Vue.config.productionTip = false


Vue.use(VueExcelXlsx);
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
