// apiRepository.js
const apiPrefix = "/api/v1/";
const API_URLS = {
  segments: {
    list: `${apiPrefix}segments`,
    edit: "user/edit",
    update: "user/update",
  },
  flow: {
    store: `${apiPrefix}flows`,
    update: `${apiPrefix}flows`,
    edit: `${apiPrefix}flows`,
    list: `${apiPrefix}flows`,
    add: `${apiPrefix}flow/step/add`,
    updateStep: `${apiPrefix}flow/step/update`,
    resort: `${apiPrefix}flow/step/resort`,
    cloneStep: `${apiPrefix}flow/step/duplicate`,
    deleteStep: `${apiPrefix}flow/step/delete`,
    stepEmailData: `${apiPrefix}flow/step/email`,
    updateStepEmail: `${apiPrefix}flow/step/email`,
  },
  email_templates: {
    save: `${apiPrefix}user/email/templates/save`,
    list: `${apiPrefix}user/email/templates/list`,
    delete: `${apiPrefix}user/email/templates/delete`,
    update: `${apiPrefix}user/email/templates/update`,
    getDetail: `${apiPrefix}email/templates/show`,
    show: `${apiPrefix}user/email/templates/show`,
    testEmail: `${apiPrefix}user/email/templates/send-test-mail/{id}`,
    duplicateEmail: `${apiPrefix}user/email/templates/duplicate/{id}`,
  },
  verified_senders: {
    save: `${apiPrefix}email-senders`,
    list: `${apiPrefix}email-senders`,
    update: `${apiPrefix}email-senders/{id}`,
    edit: `${apiPrefix}email-senders/{id}`,
    delete: `${apiPrefix}email-senders`,
    resend: `${apiPrefix}email-senders/{id}/resend-email`,
    verify: `${apiPrefix}email-senders/{id}/verify`,
  },
  webhooks: {
    save: `/user/webhooks/store`,
    list: `/user/webhooks/list`,
    update: `/user/webhooks/update/{id}`,
    edit: `/user/webhooks/edit/{id}`,
    quizList: `/user/webhooks/create`,
    delete: `/user/webhooks/{id}`,
    logList: `/user/webhooks/logs`,
    webhook_list_secret: `/webhooks/list`,
    webhook_test: "/webhooks/test",
    get_webhook_secret: "/webhooks/secrets/show",
    createWebhookSecret: "/webhooks/secrets/regenerate",
  },
  customInputs: {
    save: `/custom/field/create`,
    list: `/custom/field/list`,
    delete: `/custom/field/remove/{id}`,
  },
  wixProducts: {
    getFromWix: "/wix/products-load",
    saveWixProducts: "/wix/products-import",
    getWixVariantsProducts: "/wix/variants-as-products/load",
    saveWixVariantProducts: "/wix/variants-as-products/import",
    getWixCollection: "/collection/list?per_page:10000",

    // save: `/custom/field/create`,

    // list: `/custom/field/list`,
    // delete: `/custom/field/remove/{id}`,
  },///
  wooCommerceProducts: {
    getFromStore: "/loadWordpressProductsWithFilters",
    saveWooCommerceProducts: "/syncWordpressProducts",
    // save: `/custom/field/create`,
    // list: `/custom/field/list`,
    // delete: `/custom/field/remove/{id}`,
  },
  leads: {
    export: "/leads/export",
    exportWithQuiz: "/leads/export-with-answers",
  },
  analysis: {
    exportProducts: "/api/v1/product-analytics/export",
    resetProducts: "/api/v1/product/analytics/reset",
    resetQuestions: "/api/v1/question-analytics/reset",
    resetChart: "/api/v1/quiz-analytics/reset",
    exportQuestions: "/api/v1/question-analytics/export",
    // Old
    exportProductsOld: "/api/v1/product-analytics/export",
    resetProductsOld: "/product/analytics/reset",
    resetQuestionsOld: "/resetQuestionsAnalytics",
    resetChartOld: "/resetGraphAnalytics",
    exportQuestionsOld: "/loadAnalyses/export/questions",
  },
  products: {
    duplicate: "/duplicateProduct/{id}",
    filter: "/productListFilters",
  },
  rolesPermissions: {
    invitation: "/invite",
    list: "/invitations",
    delete: "/invite/{id}",
    checkInviteToken: "/invitation/accept",
    switchWorkspace: "/workspaces/switch",
  },
  userData: {
    info: "/loadUserProfile",
    account: "/loadAccountData",
  },
  // Add more resources and endpoints as needed
};
export default API_URLS;