import axios from "axios";
import store from "../store/store";
// const { state } = store;
async function nextPreviewPage(payload) {
  store.commit("setIsLoadingQuestion", true);
  await axios
    .post("/api/loadQuizNextPage", payload)
    .then((response) => {
      if (response.status == 200) {
        moveToPage(response.data.data);
      }
    })
    .catch((error) => error);
  store.commit("setIsLoadingQuestion", false);
}
async function nextJumpLogicPage(payload) {
  store.commit("setIsLoadingQuestion", true);
  await axios
    .post("/api/loadExactQuizPage", payload)
    .then((response) => {
      if (response.status == 200) {
        moveToPage(response.data);
      }
    })
    .catch((error) => error);
  store.commit("setIsLoadingQuestion", false);
}
async function moveToPage(page) {
  page = page.data ? page.data : page;
  store.commit("setSelectedPage", JSON.parse(JSON.stringify(page)));
  store.commit("setTotalPageSingStep", page);
}
export { nextPreviewPage, nextJumpLogicPage };
