<template>
  <div class="">
    <Header class="pb-4" :heading="title">
        
    </Header>
    <div class="mb-5">
        <div class="card border-radius" style="border:1px solid var(--border-color)">
            <div class="card-body">
                      
                    <div class="d-flex align-items-center menu mb-4">
                        <router-link :to="{name:link.routerName}" v-for="link in links" :key="link.name">{{link.name}}</router-link>
                    </div>
                    
                
        <router-view></router-view>
    </div>
    </div>
<FooterContent v-if="routeName">
<div class="d-flex align-items-center justify-content-end">
    <Button 
            :isLoading="isLoading"
        @click="triggerAxiosCall"
          >Create Private API key</Button
        >
</div>
</FooterContent>
</div>
   
  </div>
</template>

<script>
import Button from '../Layout/Button.vue';
import Header from '../Layout/Header.vue';
import FooterContent from '../Layout/FooterContent.vue';
export default {
    props:{
        title:{
            type:String,
            default:'Account'
        },
        links:{
            type:Array,
            default:()=>[{
        name:'View Profile',
        routerName:'UserProfile'
        },
        {
            name:'Invite Users',
            routerName:'RolesAndPermission'
        },
        {
            name:'Invoices and Billing',
            routerName:'Invoice'
        },
        {
            name:'API',
            routerName:'AccessToken'
        },
        {
            name:'Sender Detail',
            routerName:'SenderEmailVerification'
        }
    ]
        }
    },
    components:{
        Header,
        Button,
        FooterContent
    },
data(){
    return {
        isLoading:false,
      
    }
},
computed:{
    routeName() {
      return this.$route.name == 'AccessToken';
    }
},
methods:{
   async triggerAxiosCall() {
    this.isLoading=true;
      const component = this.$children.find(comp=>comp.isCreateTokenLoading!=undefined);
      if (component) {
       await component.CreateToken();
      }
      this.isLoading=false;
    }
}
}
</script>

<style scoped>
.heading{
    color: #1F1F28;
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 100%;
}
.menu{
    gap:20px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 15px;
}
.menu a{
    color: #73738D;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
text-decoration: none;
position: relative;
}
.menu a.router-link-active{
    color:var(--primary-color)
}
.menu a.router-link-active:after{
    display: block;
    content: '';
    position: absolute;
    bottom: -15px;
    height: 1px;
    /* margin-bottom: 20px; */
    width: 100%;
    background: var(--primary-color);
}
</style>