<template>
    <div class="row">
              <div
                class="col-12"
              >
              <div class=" d-flex flex-wrap justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="">
                    <router-link
                      class="table-action-button mr-2 align-items-center"
                      :to="back"
                      v-if="back"
                    >
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                      
                    
                    </router-link>
                  </div>
                  <div class="d-flex" style="gap:var(--gap);">
                    <p class="headTitle m-0">{{ heading }}</p>
                    <div class="sub-heading"> {{ subHeading }} </div>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-wrap" style="gap:8px;">
                  <div class="">
                    <form @submit.prevent="$emit('click')">
                    <Button
                 :isLoading="isLoading" type="submit" v-if="isSaveBtn" >Save
                </Button>
</form>
                </div>
                <slot name="buttons"></slot>
                </div>
                </div>
              </div>
            </div>
</template>
<script>

import Button from './Button.vue';
export default{
    props:{
        heading:String,
        isSaveBtn:Boolean,
        isLoading:Boolean,
        back:String,
        subHeading:String
    },
    components:{
      Button
    }
}
</script>
<style scoped>
.headTitle {
  color: #1F1F28;

font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
  line-height:1;
}
.table-action-button{
  text-decoration: none;
  background:var(--white);
 
padding: 7px;

display:block;
gap: 4px;
}
.sub-heading{
  color: var(--grey-button);
display:flex;
align-items:flex-end;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
display: flex;
justify-content: flex-end;
}
</style>