<template>
        
            <button :type="type" class="btn ButtonHover px-3 py-2" :class="btnVariant=='shopify'?'shopify-btn':btnVariant=='yellow'?'yellow-btn':'quizell-btn'" ref="btn" @click="$emit('click')" v-bind="$attrs" >
              <svg v-if="isTick&&!isLoading" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1667 7.5L8.33332 13.3333L5.83328 10.8333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

              <span v-if="!isLoading"><slot>
                Button
              </slot></span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
       
</template>
<script>


export default ({
  name:'Button',
  props:{
    type:String,
    isLoading:Boolean,
    shopifyBtn:Boolean,
    btnVariant:{
      default:'quizell',
      type:String
    },
    isTick:Boolean
  },
  watch:{
    isLoading(newVal){
    
      this.$refs.btn.disabled=newVal
      
    }
  }
})
</script>
<style scoped>
.quizell-btn, .quizell-btn:hover{
    background: #4d1b7e;
    color:#fff;
    border-radius: 6px;
    min-width: 119px;
    font-size: 14px;
}
.quizell-btn:not(:disabled):not(.disabled):active {
     box-shadow: inset 0 0 10.2px 0.5rem rgba(80, 58, 60, 0.25)!important;
}
.quizell-btn:active{
    background-color: #1f0637;
  color: #ffffff;
  border-color:#1f0637;
}
.shopify-btn:active,.shopify-btn:hover {
  background-color: #002e25;
  color: #ffffff;
  border-color: #002e25;
}

.shopify-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #008060;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  font-size: 14px;
  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.shopify-btn:not(:disabled):not(.disabled):active {
     box-shadow: inset 0 0 10.2px 0.5rem rgba(80, 58, 60, 0.25)!important;
}
.yellow-btn{
    background: #ffa201;
    color:#fff;
    border-radius: 6px;
    min-width: 119px;
    font-size: 14px;
}
.yellow-btn:not(:disabled):not(.disabled):active {
     box-shadow: inset 0 0 10.2px 0.5rem rgba(80, 58, 60, 0.25)!important;
}
.yellow-btn:active{
    background: #62430c;
  color: #ffffff;
  border-color:#1f0637;
}
.quizell-btn, .quizell-btn:hover {
    background: #4d1b7e;
    color: #fff;
    border-radius: 6px;
    min-width: 119px;
    font-size: 14px;
}
</style>
