import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persist-indexeddb";

import actions from "./action";
import mutations from "./mutations";
import getters from "./getters";

Vue.use(Vuex);
function getDefaultState() {
  return {
    kozimaTimerSec:55,
    kozimaIntervalValue:null,
    kozimCurrectSectionIndex:0,
    kozmikQuizDetail:[],
    pageCounter: {},
    currLeadPageCounter: {
      counter: 0,
      currLeadPageId: [],
    },
    isLoadingQuestion: false,
    selectedTemplateDesign: {},
    totalPages: [],
    previewTotalPages: [],
    selectedPage: {},
    progressBar: {},
    previewProgressBar: {},
    EditorSettings: {},
    Currencies: [],
    previewSettings: {},
    selectedOptions: {
      questionAnswer: [],

    },
    selectedRangeSliders:[],
    shopOrigin: "",
    apiKey: null,
    isShopifyUser: "",
    accountSetting: {},
    loggedInUserPlanDetails: {},
    UserAllAccountData: {},
    limitations: [],
    shopifySelectedProducts: "",
    previewPagesStack: [],
    currPageOrder: null,
    uploadedImage: null,
    isAnyPageEdited: false,
    isloadQuizCustomizationTriggered: false,
    isupdateQuizCustomizationTriggered: false,
    editorPageView: "desktop",
    currSidebarView:'pageDesign',
    savedLeadId: {
      address: {},
      custom_inputs: {},
      date: "",
      email: "",
      freeTextNote: [],
      full_name: "",
      website: "",
      organisation: "",
      phone_number: "",
      terms_conditions: false,
      fileUpload: [],
      quiz_analytic_id: null,
    },
    translation: {},
    QuizStarted: false,
    QuizId: "",
    QuizKey: "",
    CurrentQuizTitle: "",
    UserShareData: {},
    LoadQuizAppsIntegrated: [],
    UpgradePlanModal: {
      isOpen: false,
      title: "",
      subText: "",
    },
    pageZoom: 75,
    trackIkeaClientCounter: {},
    currentActiveCounterKey: "",
    isCustomNavOpen: true,
    isFlowComponentsDragging: false,
    currentEmailDesignEditData: {},
    userCustomLeadsInput: [],
    quizLoader: {
      selectedLoader: "DefaultLoader",
      selectedLoaderColor: "#4d1b7e",
      customLoader: {
        enabled: false,
        customLoaderImage: "",
      },
    },
    isInvitedUser: false,
    isLoadAccountDataLoading:false,
    isEditQuizSaveLoading:false,

    //Font Management
    fontFamilyTracker:{},
    loadedFonts: [],
    editorFontList:[]
  };
}

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: getDefaultState,

  getters: getters,
  actions: actions,
  mutations: mutations,

});
