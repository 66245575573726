import axios from "axios";
import WebFont from 'webfontloader';
import router from "../router/index";
import API_URLS from "./Repository";
import { getFormatedUTCTimeStamp } from "../composibles/getUTCTimeStamp";
import { nextPreviewPage } from "../composibles/singleStepPreviewApi";
export default {
  // eslint-disable-next-line no-unused-vars

  async fetchData(
    // eslint-disable-next-line no-unused-vars
    { commit },
    { axiosMethod, resource, method, params, id = "" }
  ) {
    if (!API_URLS[resource] || !API_URLS[resource][method]) {
      console.error("Invalid API URL");
      return false;
    }
    let endpoint = "";
    const methodContainsId = API_URLS[resource][method].includes("{id}");
    if (methodContainsId) {
      endpoint = API_URLS[resource][method].replace("{id}", id);
    } else if (id) endpoint = API_URLS[resource][method] + "/" + id;
    else endpoint = API_URLS[resource][method];
    const axiosConfig = params;
    return await axios[axiosMethod](endpoint, axiosConfig)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return false;
      });
  },

  goToBackPreviewPage({ commit, state }) {
    const currentPageOrder = state.selectedPage.order;
    let current = currentPageOrder;
    if (state.previewPagesStack.length > 0) {
      const type = "pop";
      commit("PreviewPagesStack", { type });
    } else {
      if (currentPageOrder > 0) {
        current -= 1;
        commit(
          "setSelectedPage",
          JSON.parse(JSON.stringify(state.previewTotalPages[current]))
        );
      }
    }
  },
  async goToNextPreviewPage({ commit, state }, pageValue) {
    const length = state.previewTotalPages.length;
    const currentPageOrder = state.selectedPage.order;
    let { single_step_status } = state.previewSettings;
    if (single_step_status) {
      await nextPreviewPage({
        order: state.selectedPage.order + 1,
        api_token: router.currentRoute.params.id,
      });
    } else {
      let current = currentPageOrder;
      if (length - currentPageOrder > 1) {
        if (pageValue !== "getStartedPage") {
          const currPageObj = state.previewTotalPages[currentPageOrder];
          const type = "push";
          commit("PreviewPagesStack", { type, currPageObj });
        }
        current += 1;
        commit(
          "setSelectedPage",
          JSON.parse(JSON.stringify(state.previewTotalPages[current]))
        );
      }
    }
  },
  updateSelectedOptionsObject({ commit }, obj) {
    commit("setSelectedOptions", obj);
  },
  updateSelectedRangeSlider({ commit }, obj) {
    commit("setSelectedRangeSliders", obj);
  },
  updateProductArray({ commit, state }, arr) {
    const resultPageIndex = state.previewTotalPages.findIndex(
      (page) => page.value == "resultPage"
    );
    if (resultPageIndex) {
      const productArrayIndex = state.previewTotalPages[
        resultPageIndex
      ].pageDesign.blocksArray.findIndex((block) => block.type == "product");
      commit("setUpdateResultPreviewProducts", {
        resultPageIndex,
        productArrayIndex,
        arr,
      });
    }
  },
  updateResultPreviewPageType({ commit, state }, pageType) {
    const resultPageIndex = state.previewTotalPages.findIndex(
      (page) => page.value == "resultPage"
    );
    if (resultPageIndex) {
      commit("setupdateResultPreviewPageType", { resultPageIndex, pageType });
    }
  },
  createEndQuizPage({ commit }, description) {
    const obj = {};
    (obj.value = "endQuiz"),
      (obj.pageDesign = {
        pageStyles: {
          activeBlockTitle: "Styles",
          activeSubBlock: false,
          paddingTop: 10,
          paddingRight: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          marginLeft: 10,
          marginRight: 10,
          alignItems: "center",
          textAlign: "center",
          backgroundColor: "#ffffff",
          color: "black",
          imageAlign: "left",
          imageFor: "both",
          imageSrc: "/images/test/side_default.png",
          bgImageStatus: false,
          bgImageSrc: false,
          fontFamily: "",
          fontSize: "",
          height: 640,
          width: 850,
        },

        blocksArray: [
          {
            type: "text",
            content: description,
            style: {
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 10,
              marginRight: 10,
              textOpacity: 100,
              color: "#000000",
              rotate: 0,
              height: 150,
              lineHeight: 1,
              textDecoration: "unset",
              fontWeight: "bold",
              fontStyle: "normal",
              textAlign: "center",
              fontSize: 32,
              fontFamily: "Poppins",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
          {
            type: "button",
            content: "Restart Quiz",
            text: "Restart Quiz",
            style: {
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 15,
              paddingBottom: 15,
              backgroundColor: "#000000",
              color: "#ffffff",
              textAlign: "center",
              fontWeight: "normal",
              fontStyle: "normal",
              buttonWidth: 150,
              buttonHeight: 54,
              widthIsAuto: true,
              borderRadius: 8,
              borderSize: 0,
              borderColor: "",
              position: "center",
              textOpacity: 100,
              backgroundOpacity: 100,
              fontSize: 14,
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 10,
              marginRight: 10,
              lineHeight: 1,
              textDecoration: "unset",
              fontFamily: "Poppins",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
        ],
        imageBlock: {
          src: "",
          customizeStatus: false,
          hoverStatus: false,
          style: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
            paddingBottom: 10,
            textAlign: "center",
            backgroundColor: "none",
            width: 300,
            height: 250,

            rotate: 0,
            imageOpacity: 100,
            displayMobile: false,
            displayDesktop: false,
          },
        },
      });
    commit("showEndQuizPage", obj);
  },
  loadNavbarData({ commit }) {
    // const token =  document.querySelector('meta[name="csrf-token"]').content;
    axios
      .post("/loadNavbarData", {})
      .then((resp) => {
        commit("setNavbarData", resp.data.data.account);
        commit("setUserPlanData", resp.data.data.plan);
        commit("setUserLimitation", resp.data.data.limitations);
      })
      .catch((error) => {
        console.log(error);
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      });
  },
  async loadAccountDataVuex({ commit }) {
    try {
      commit("toggleIsAccountDataLoading", true);

      // let data = {};
      const response = await axios.post(`/loadAccountData`);
      if (response.status == 200) {
        commit("setUserAllAccountData", response.data.data);
      }
    } catch (error) {
      if (error) {
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
      throw error;
    } finally {
      commit("toggleIsAccountDataLoading", false);
    }
  },
  async updateQuizAnalysesAction(
    { commit },
    { api_token, quizId, value_type }
  ) {
    try {
      let data = {
        api_token: api_token,
        quizId: quizId,
        value_type: value_type,
        started_at: getFormatedUTCTimeStamp(),
      };
      const response = await axios.post(`/api/updateQuizAnalytic`, data);
      if (response.status == 200 && response.data.status == "success") {
        let { quiz_analytic_id } = response.data.data;
        if (quiz_analytic_id) {
          commit("assignQuizAnaylticsId", quiz_analytic_id);
          return true;
        }
        return false;
      }
    } catch (error) {
      this.$toasted.show("Error occured ", {
        theme: "toasted-primary",
        type: "error",
        position: "bottom-center",
        duration: 2000,
      });
    }
  },

  populateTrackCounter({ commit, getters }) {
    const keys = Object.keys(getters.getFurnitureClientCounterData());

    let newObj = {};

    keys.forEach((key) => {
      if (!(key in newObj)) {
        newObj[key] = {
          ...getters.getFurnitureClientCounterData()[key],
          counter: 0,
        };
      }
    });

    commit("setPopulateTrackCounter", newObj);
  },

  updateTrackCounter({ commit, getters }, key) {
    let counter1 = getters.getFurnitureClientCounterData()[key].counter;
    let counter2 = getters.getTrackIkeaClientCounter[key].counter;

    if (counter1 > counter2) {
      commit("setIkeaClientCounter", key);
    }
  },

  resetKozmicTimer({ commit, getters }) {
    const isTimerActive = getters.getkozimaIntervalValue != null;
    if (isTimerActive) {
      clearInterval(getters.getkozimaIntervalValue);
      commit("setKozimaIntervalValue", null);
    }
    commit("setKozimaTimerSec", 55);
  },
  startKozmicTimer({ commit, getters, dispatch }) {
    const getTotalPreviewPages = getters.getTotalPreviewPages;
    const isTimerActive = getters.getkozimaIntervalValue != null;
    let counter = getters.getKozimaTimerSec;
    if (!isTimerActive) {
      const timer = setInterval(() => {
        counter -= 1;
        commit("setKozimaTimerSec", counter);
  
        if (counter <= 0) {
          clearInterval(timer); 
  
          const updatedCurrKosmikIndex = getters.getKozimCurrectSectionIndex;
          const updatedCurrentSection = { ...getters.getKozmikCurrentSection };
          const resultPageIndex = getters.getTotalPreviewPages.findIndex(
            (page) => page.value == "resultPage"
          );
  
          
  
          if (updatedCurrKosmikIndex === 2) {
           
            commit("jumpToPreviewPage", resultPageIndex);
            dispatch("resetKozmicTimer");
          } else {
            commit("setKozimCurrectSectionIndex", updatedCurrentSection.nextIndex);
  
            const nextSection =
              getters.getkozmikQuizDetail[updatedCurrentSection.nextIndex];
            const nextGetStartedPageIndex = nextSection
              ? getTotalPreviewPages.findIndex(
                  (page) => page.id === nextSection.getStartedId
                )
              : -1;
  
            commit(
              "jumpToPreviewPage",
              nextGetStartedPageIndex > -1
                ? nextGetStartedPageIndex
                : resultPageIndex
            );
          }
        }
      }, 1000);
  
      commit("setKozimaIntervalValue", timer);
    }
  },
  
  handleKozmicGetStartedTimerReset(
    { getters, dispatch, commit },
    currentPageId
  ) {

    function getCurrentGetStartedPageIndex(arr, currPageId) {
      for (let i = 0; i < arr.length; i++) {
        // Check if the getStartedId matches the provided id
        if (arr[i].getStartedId == currPageId) {
          return i;
        }
      }
      return -1;
    }

    const getKosmikStartedPageIndex = getCurrentGetStartedPageIndex(
      getters.getkozmikQuizDetail,
      currentPageId
    );

    if (getKosmikStartedPageIndex > -1) {
    
      //update the kozimCurrectSectionIndex

      commit("setKozimCurrectSectionIndex", getKosmikStartedPageIndex);

      //reset the timer
      dispatch("resetKozmicTimer");``
    }
  },
  async kozimaCheckTimer({ getters, dispatch }, currentPageId) {
    const currentSection = JSON.parse(
      JSON.stringify(getters.getKozmikCurrentSection)
    );

    //if the single Answer page is first then start the timer
    if (currentSection.questions[0] == currentPageId) {
      dispatch("startKozmicTimer");
    } 
   
  },

  //Font Management 
  processFontFamily({ commit , dispatch }, payload ) {
    const { totalPages, progressBarValue } = payload;
    const fontFamilyTracker = {};
    const incrementFontFamilyCount = (fontFamily) => {
      if (!fontFamily) return;
      if (fontFamilyTracker[fontFamily]) {
        fontFamilyTracker[fontFamily]++;
      } else {
        fontFamilyTracker[fontFamily] = 1;
      }
    };

    try {
      totalPages.forEach((item) => {
        if (item.value === "resultPage") {
          item.pageDesign.NoResultBlocksArray.forEach((element) => {
            switch (element.type) {
              case "text":
                incrementFontFamilyCount(element.style.fontFamily);
                break;
              case "button":
                if (typeof element.content !== "object") {
                  incrementFontFamilyCount(element.style.fontFamily);
                } else {
                  incrementFontFamilyCount(element.content.backButton.style.fontFamily);
                  incrementFontFamilyCount(element.content.nextButton.style.fontFamily);
                }
                break;
            }
          });
        }

        item.pageDesign.blocksArray.forEach((element) => {
          switch (element.type) {
            case "text":
              incrementFontFamilyCount(element.style.fontFamily);
              break;
            case "button":
              if (typeof element.content !== "object") {
                incrementFontFamilyCount(element.style.fontFamily);
              } else {
                incrementFontFamilyCount(element.content.backButton.style.fontFamily);
                incrementFontFamilyCount(element.content.nextButton.style.fontFamily);
              }
              break;
            case "option":
              incrementFontFamilyCount(element.style.fontFamily);
              break;
            case "freeText":
              incrementFontFamilyCount(element.style.fontFamily);
              incrementFontFamilyCount(element.style.DateFontFamily);
              break;
            case "checkbox":
              incrementFontFamilyCount(element.style.fontFamily);
              break;
            case "product":
              incrementFontFamilyCount(element.style.bulletDescriptionfontFamily);
              incrementFontFamilyCount(element.style.detailListFontFamily);
              incrementFontFamilyCount(element.style.fontFamily);
              incrementFontFamilyCount(element.style.productVariantStyles.fontFamily);
              break;
          }
        });
      });
      if (typeof progressBarValue === 'undefined') {
        console.log("progressBarValue not received");
        // Optionally, you can handle the case when it's not present
        return;
      }
      if(progressBarValue){
      incrementFontFamilyCount(progressBarValue.progressStyles.fontFamily);
      }

      commit("SET_FONT_FAMILY_TRACKER", fontFamilyTracker);
      dispatch("loadFontsFromTracker");
    } catch (error) {
      console.error("Error processing font family", error);
    }
  },
  updateFontFamily({ commit }, { oldFontFamily, newFontFamily }) {

    if (oldFontFamily) {
      commit('DECREMENT_FONT_FAMILY_COUNT', oldFontFamily);
    }
    
    commit('INCREMENT_FONT_FAMILY_COUNT', newFontFamily);
  },
  loadFontsFromTracker({ state }) {
    const fontFamilies = Object.keys(state.fontFamilyTracker);
    fontFamilies.forEach((fontFamily) => {
      if (!state.loadedFonts.includes(fontFamily)) {
        console.log("Loading Font",fontFamily)
        WebFont.load({
          google: {
            families: [fontFamily]
          },
          active() {
            state.loadedFonts.push(fontFamily);
          },
          inactive() {
            console.error(`Failed to load ${fontFamily}`);
          }
        });
      }
    });
  },
  async getWebsiteColorAction(_, { websiteLink }) {
    try {
      const { data, status } = await axios.get(`/quiz/get-colors?website_link=${websiteLink}`);
      return status === 200 ? data.data : false;
    } catch (error) {
      this.$toasted.show("Error occurred while fetching colors", {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
      return false;
    }
  },
  formatUrl(_, url) {
    if (!url) return ''; 

    
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  }
};
